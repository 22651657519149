header {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    text-align: center;
  }
  
  header img {
    width: 7rem;
  }
  
  header h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  header p {
    margin: 0;
  }