.company {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    background: linear-gradient(#250358, #440c66);
    padding: 1.5rem;
    border-radius: 6px;
    box-shadow: 0 2px 20px rgba(234, 78, 240, 0.2);
    width: 33%;
    transition: all 0.2s
  }
  
  .company:hover {
    transform: translateY(-2px);
    transition: transform 0.2s;
    box-shadow: 0 2px 30px rgba(234, 78, 240, 0.4);
  }
  
  .company img {
    width: 6rem;
    height: 5rem;
    object-fit: contain;
  }
  
  .company h2 {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
  }
  
  .company p {
    margin: 0;
    text-align: center;
    font-size: 0.85rem;
  }